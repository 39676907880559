// import React, { useState, useEffect } from 'react';
// import { Calendar, Clock, Users, Target, Award, BookOpen } from 'lucide-react';
// import { useNavigate, useParams } from 'react-router-dom';
// import API_URL from '../../constants/Constants';

// const TrainingDetail = () => {
//   const navigate = useNavigate();
//   const { id } = useParams();

//   const [training, setTraining] = useState(null);
//   const [schedules, setSchedules] = useState([]);
//   const [isLoading, setIsLoading] = useState(true);
//   const [schedulesError, setSchedulesError] = useState(null);

//   useEffect(() => {
//     const fetchTrainingDetails = async () => {
//       try {
//         // Fetch training details
//         const trainingResponse = await fetch(`${API_URL}training/${id}`);
//         if (!trainingResponse.ok) {
//           throw new Error('Failed to fetch training details');
//         }
//         const trainingData = await trainingResponse.json();
//         setTraining(trainingData);

//         // Fetch training schedules
//         try {
//           const schedulesResponse = await fetch(`${API_URL}training-schedules/training/${id}`);
//           if (!schedulesResponse.ok) {
//             // Instead of throwing an error, we'll set an empty array and allow rendering
//             setSchedules([]);
//             setSchedulesError('No training schedules available');
//           } else {
//             const schedulesData = await schedulesResponse.json();
//             setSchedules(schedulesData);
//           }
//         } catch (schedulesError) {
//           console.error('Schedules fetch error:', schedulesError);
//           setSchedules([]);
//           setSchedulesError('Unable to fetch training schedules');
//         }

//         setIsLoading(false);
//       } catch (error) {
//         console.error(error);
//         setIsLoading(false);
//       }
//     };

//     fetchTrainingDetails();
//   }, [id]);

//   const handleEnrollment = (schedule) => {
//     navigate(`/enroll/${id}`, {
//       state: {
//         training: training,
//         schedule: schedule
//       }
//     });
//   };

//   const formatDate = (dateString) => {
//     const date = new Date(dateString);
//     return date.toLocaleDateString('en-GB', {
//       day: '2-digit',
//       month: '2-digit',
//       year: 'numeric'
//     });
//   };

//   if (isLoading) {
//     return <div className="text-center py-10">Loading training details...</div>;
//   }

//   if (!training) {
//     return <div className="text-center py-10">No training found</div>;
//   }

//   return (
//     <div className="min-h-screen bg-gray-50">
//       {/* Hero Section */}
//       <div className="bg-white shadow-sm">
//         <div className="max-w-6xl mx-auto px-4 py-12 sm:px-6 lg:px-8">
//           <div className="grid md:grid-cols-2 gap-8 items-center">
//             <div>
//               <h1 className="text-4xl font-bold text-gray-900 mb-4">{training.title}</h1>
//               <p className="text-lg text-gray-600 mb-6">
//                 {training.description}
//               </p>
//               <div className="flex flex-wrap gap-4">
//                 <span className="inline-flex items-center px-4 py-2 bg-blue-50 text-blue-700 rounded-full">
//                   <Clock className="w-4 h-4 mr-2" /> {training.duration} Days
//                 </span>
//                 <span className="inline-flex items-center px-4 py-2 bg-green-50 text-green-700 rounded-full">
//                   <Award className="w-4 h-4 mr-2" /> {training.is_certified ? 'Certificate Included' : 'No Certificate'}</span>
//               </div>
//             </div>
//             <div className="relative">
//               <img
//                 src="/images/1.jpg"
//                 alt={training.title}
//                 className="rounded-lg shadow-lg object-cover w-full h-[300px]"
//               />
//             </div>
//           </div>
//         </div>
//       </div>

//       {/* Main Content */}
//       <div className="max-w-6xl mx-auto px-4 py-12 sm:px-6 lg:px-8">
//         <div className="grid md:grid-cols-3 gap-8">
//           {/* Left Column - Course Details */}
//           <div className="md:col-span-2 space-y-8">
//             <section className="bg-white rounded-xl shadow-sm p-6">
//               <h2 className="text-2xl font-semibold text-gray-900 mb-6">What You'll Learn</h2>
//               <div className="grid sm:grid-cols-2 gap-4">
//                 {training.what_you_will_learn.map((skill, index) => (
//                   <div key={index} className="flex items-start">
//                     <Target className="w-5 h-5 text-blue-600 mt-1 mr-3" />
//                     <div>
//                       <h3 className="font-medium text-gray-900">{skill}</h3>
//                     </div>
//                   </div>
//                 ))}
//               </div>
//             </section>

//             <section className="bg-white rounded-xl shadow-sm p-6">
//               <h2 className="text-2xl font-semibold text-gray-900 mb-6">Course Schedule</h2>
//               <div className="overflow-hidden">
//                 {schedules.length > 0 ? (
//                   <table className="min-w-full">
//                     <thead>
//                       <tr className="border-b border-gray-200">
//                         <th className="text-left py-3 px-4 font-semibold text-gray-900">Training Dates</th>
//                         <th className="text-right py-3 px-4"></th>
//                       </tr>
//                     </thead>
//                     <tbody className="divide-y divide-gray-200">
//                       {schedules.map((schedule) => (
//                         <tr key={schedule.id}>
//                           <td className="py-4 px-4 flex items-center">
//                             <Calendar className="w-5 h-5 text-blue-600 mr-3" />
//                             <span className="text-gray-900">
//                               {formatDate(schedule.start_date)} - {formatDate(schedule.end_date)}
//                             </span>
//                           </td>
//                           <td className="py-4 px-4 text-right">
//                             <button
//                               onClick={() => handleEnrollment(schedule)}
//                               className="inline-flex items-center px-4 py-2 border border-blue-600 bg-blue-50 text-blue-600 rounded-lg hover:bg-blue-50 transition-colors"
//                             >
//                               Register
//                             </button>
//                           </td>
//                         </tr>
//                       ))}
//                     </tbody>
//                   </table>
//                 ) : (
//                   <div className="text-center py-6 text-gray-500">
//                     {schedulesError || 'No upcoming schedules available for this training'}
//                   </div>
//                 )}
//               </div>
//             </section>
//           </div>

//           {/* Right Column - Price Card */}
//           <div className="md:col-span-1">
//             <div className="bg-white rounded-xl shadow-sm p-6 sticky top-4">
//               <h2 className="text-2xl font-semibold text-gray-900 mb-4">Course Fee</h2>
//               <div className="text-3xl font-bold text-gray-900 mb-6">
//                 {training.fee} $
//               </div>
//               <div className="space-y-4 mb-6">
//                 <div className="flex items-center text-gray-600">
//                   <Clock className="w-5 h-5 mr-3" />
//                   <span>{training.duration} days intensive training</span>
//                 </div>
//                 <div className="flex items-center text-gray-600">
//                   <Award className="w-5 h-5 mr-3" />
//                   <span>{training.is_certified ? 'Certificate of completion' : 'No certificate'}</span>
//                 </div>
//                 <div className="flex items-center text-gray-600">
//                   <Users className="w-5 h-5 mr-3" />
//                   <span>By {training.instructor}</span>
//                 </div>
//               </div>
// {/* 
//               <button
//                 onClick={() => handleEnrollment(schedules[0])}
//                 className="w-full bg-blue-600 text-white py-3 px-4 rounded-lg hover:bg-blue-700 transition-colors"
//               >
//                 Register Now
//               </button> */}
//             </div>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default TrainingDetail;

import React, { useState, useEffect } from 'react';
import { Calendar, Clock, Users, Target, Award, BookOpen, MapPin, School, Star } from 'lucide-react';
import { useNavigate, useParams } from 'react-router-dom';
import API_URL from '../../constants/Constants';

const TrainingDetail = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [activeTab, setActiveTab] = useState('overview');
  const [training, setTraining] = useState(null);
  const [schedules, setSchedules] = useState([]);
  const [reviews, setReviews] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [schedulesError, setSchedulesError] = useState(null);

  useEffect(() => {
    const fetchTrainingDetails = async () => {
      try {
        const trainingResponse = await fetch(`${API_URL}training/${id}`);
        if (!trainingResponse.ok) throw new Error('Failed to fetch training details');
        const trainingData = await trainingResponse.json();
        setTraining(trainingData);

        try {
          const schedulesResponse = await fetch(`${API_URL}training-schedules/training/${id}`);
          if (!schedulesResponse.ok) {
            setSchedules([]);
            setSchedulesError('No training schedules available');
          } else {
            const schedulesData = await schedulesResponse.json();
            setSchedules(schedulesData);
          }

          // Fetch reviews
          const reviewsResponse = await fetch(`${API_URL}training-reviews/${id}`);
          if (reviewsResponse.ok) {
            const reviewsData = await reviewsResponse.json();
            setReviews(reviewsData);
          }
        } catch (error) {
          console.error('Data fetch error:', error);
          setSchedulesError('Unable to fetch data');
        }

        setIsLoading(false);
      } catch (error) {
        console.error(error);
        setIsLoading(false);
      }
    };

    fetchTrainingDetails();
  }, [id]);

  const handleEnrollment = (schedule) => {
    navigate(`/enroll/${id}`, {
      state: { training, schedule }
    });
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleDateString('en-GB', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric'
    });
  };
  const formatTimeRange = (startDate, endDate) => {
    const start = new Date(startDate);
    const end = new Date(endDate);
    
    const daysUntilStart = Math.ceil((start - new Date()) / (1000 * 60 * 60 * 24));
    const duration = Math.ceil((end - start) / (1000 * 60 * 60 * 24))+1;
    // duration=duration+1;
    
    return {
      daysUntilStart,
      duration
    };
  };

  if (isLoading) return <div className="text-center py-10">Loading training details...</div>;
  if (!training) return <div className="text-center py-10">No training found</div>;

  const discountedPrice = parseFloat(training.fee);
  const originalPrice = parseFloat(training.original_fee);
  const hasDiscount = discountedPrice < originalPrice;

  const renderStars = (rating) => {
    return [...Array(5)].map((_, index) => (
      <Star
        key={index}
        className={`w-4 h-4 ${index < rating ? 'text-yellow-400 fill-yellow-400' : 'text-gray-300'
          }`}
      />
    ));
  };

  return (
    <div className="min-h-screen bg-gray-50">
      {/* Hero Section */}
      <div className="bg-white shadow-sm">
        <div className="max-w-6xl mx-auto px-4 py-8 sm:px-6 lg:px-8">
          <div className="grid md:grid-cols-2 gap-8 items-start">
            <div>
              <div className="flex items-center gap-2 mb-4">
                <School className="w-5 h-5 text-blue-600" />
                <span className="text-blue-600 font-medium">{training.category.name}</span>
              </div>
              <h1 className="text-4xl font-bold text-gray-900 mb-4">{training.title}</h1>
              <p className="text-lg text-gray-600 mb-6">{training.description}</p>
              <div className="flex flex-wrap gap-4 mb-6">
                <span className="inline-flex items-center px-4 py-2 bg-blue-50 text-blue-700 rounded-full">
                  <Clock className="w-4 h-4 mr-2" /> {training.duration} Days
                </span>
                <span className="inline-flex items-center px-4 py-2 bg-purple-50 text-purple-700 rounded-full">
                  <School className="w-4 h-4 mr-2" /> {training.level}
                </span>
                <span className="inline-flex items-center px-4 py-2 bg-green-50 text-green-700 rounded-full">
                  <Award className="w-4 h-4 mr-2" /> {training.is_certified ? 'Certificate Included' : 'No Certificate'}
                </span>
              </div>

              {/* Upcoming Schedule Preview */}
              {schedules.length > 0 && (
                <div className="bg-gray-50 rounded-lg p-6 mb-6">
                  <div className="flex items-center justify-between mb-4">
                    <h3 className="text-lg font-semibold">Next Available Session</h3>
                    <button
                      onClick={() => {
                        setActiveTab('schedule');
                        // Scroll to schedule section smoothly
                        document.querySelector('[data-tab="schedule"]')?.scrollIntoView({ behavior: 'smooth' });
                      }}
                      className="text-sm text-blue-600 hover:text-blue-700 font-medium flex items-center gap-1"
                    >
                      View all available sessions
                      <span className="text-xs bg-blue-100 text-blue-600 px-2 py-0.5 rounded-full ml-2">
                        {schedules.length}
                      </span>
                    </button>
                  </div>
                  <div className="bg-white rounded-lg p-6 shadow-sm">
                    <div className="grid sm:grid-cols-2 gap-6">
                      <div className="space-y-4">
                        <div className="flex items-center gap-3">
                          <Calendar className="w-5 h-5 text-blue-600" />
                          <div>
                            <div className="text-sm text-gray-500 mb-1">Start Date</div>
                            <div className="font-medium text-gray-900">{formatDate(schedules[0].start_date)}</div>
                          </div>
                        </div>
                        <div className="flex items-center gap-3">
                          <Calendar className="w-5 h-5 text-blue-600" />
                          <div>
                            <div className="text-sm text-gray-500 mb-1">End Date</div>
                            <div className="font-medium text-gray-900">{formatDate(schedules[0].end_date)}</div>
                          </div>
                        </div>
                      </div>
                      <div className="flex items-center justify-end">
                        <button
                          onClick={() => handleEnrollment(schedules[0])}
                          className="w-full sm:w-auto px-6 py-3 bg-blue-600 text-white font-medium rounded-lg hover:bg-blue-700 transition-colors"
                        >
                          Register
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>

            <div className="bg-white rounded-lg shadow-sm p-6">
              <div className="space-y-4 mb-6">
                <div className="flex items-center text-gray-600">
                  <Users className="w-5 h-5 mr-3" />
                  <span>Instructor: {training.instructor}</span>
                </div>
                <div className="flex items-center text-gray-600">
                  <MapPin className="w-5 h-5 mr-3" />
                  <span>{training.address}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Main Content */}
      <div className="max-w-6xl mx-auto px-4 py-8 sm:px-6 lg:px-8">
        <div className="space-y-6">
          {/* Custom Tabs */}
          <div className="bg-white rounded-lg border">
            <div className="flex border-b">
              {['overview', 'schedule', 'details', 'reviews'].map((tab) => (
                <button
                  key={tab}
                  data-tab={tab}
                  onClick={() => setActiveTab(tab)}
                  className={`px-6 py-3 text-sm font-medium ${activeTab === tab
                    ? 'border-b-2 border-blue-600 text-blue-600'
                    : 'text-gray-500 hover:text-gray-700'
                    }`}
                >
                  {tab.charAt(0).toUpperCase() + tab.slice(1)}
                </button>
              ))}
            </div>
          </div>

          {/* Tab Contents */}
          {activeTab === 'overview' && (
            <div className="bg-white rounded-xl shadow-sm p-6">
              <h2 className="text-2xl font-semibold text-gray-900 mb-6">What You'll Learn</h2>
              <div className="grid sm:grid-cols-2 gap-4">
                {training.what_you_will_learn.map((skill, index) => (
                  <div key={index} className="flex items-start">
                    <Target className="w-5 h-5 text-blue-600 mt-1 mr-3" />
                    <div>
                      <h3 className="font-medium text-gray-900">{skill}</h3>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          )}

          {activeTab === 'schedule' && (
            <div className="bg-white rounded-xl shadow-sm p-6">
              <h2 className="text-2xl font-semibold text-gray-900 mb-6">Available Sessions</h2>
              {schedules.length > 0 ? (
                <div className="grid gap-6">
                  {schedules.map((schedule) => {
                    const { daysUntilStart, duration } = formatTimeRange(schedule.start_date, schedule.end_date);
                    return (
                      <div key={schedule.id} className="bg-gray-50 border rounded-lg p-6 hover:border-blue-200 transition-colors">
                        <div className="flex flex-col sm:flex-row justify-between gap-4">
                          <div className="space-y-4">
                            <div className="flex items-start space-x-4">
                              <Calendar className="w-6 h-6 text-blue-600 mt-1" />
                              <div>
                                <div className="space-y-1">
                                  <div className="flex flex-col sm:flex-row sm:items-center gap-2">
                                    <span className="font-medium text-gray-900">Starts:</span>
                                    <span className="text-gray-600">{formatDate(schedule.start_date)}</span>
                                    {daysUntilStart > 0 && (
                                      <span className="text-sm text-green-600 bg-green-50 px-3 py-0.5 rounded-full">
                                        Starts in {daysUntilStart} days
                                      </span>
                                    )}
                                  </div>
                                  <div className="flex flex-col sm:flex-row sm:items-center gap-2">
                                    <span className="font-medium text-gray-900">Ends:</span>
                                    <span className="text-gray-600">{formatDate(schedule.end_date)}</span>
                                  </div>
                                </div>
                                <div className="mt-3 flex flex-wrap gap-2">
                                  {/* <span className="text-sm bg-blue-50 text-blue-700 px-3 py-1 rounded-full">
                                    {duration} days duration
                                  </span> */}
                                  <span className="text-sm bg-purple-50 text-purple-700 px-3 py-1 rounded-full">
                                    {training.level}
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="flex items-center">
                            <button
                              onClick={() => handleEnrollment(schedule)}
                              className="w-full sm:w-auto px-6 py-3 bg-blue-600 text-white font-medium rounded-lg hover:bg-blue-700 transition-colors flex items-center justify-center gap-2"
                            >
                              Register
                            </button>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              ) : (
                <div className="bg-sky-50 border border-sky-200 rounded-lg p-4 text-sky-800">
                  {schedulesError || 'No upcoming schedules available for this training'}
                </div>
              )}
            </div>
          )}

          {activeTab === 'details' && (
            <div className="bg-white rounded-xl shadow-sm p-6">
              <h2 className="text-2xl font-semibold text-gray-900 mb-6">Course Details</h2>
              <div
                className="prose max-w-none"
                dangerouslySetInnerHTML={{ __html: training.details }}
              />
            </div>
          )}

          {activeTab === 'reviews' && (
            <div className="bg-white rounded-xl shadow-sm p-6">
              <h2 className="text-2xl font-semibold text-gray-900 mb-6">Student Reviews</h2>
              {reviews.length > 0 ? (
                <div className="space-y-6">
                  {reviews.map((review) => (
                    <div key={review.id} className="border-b pb-6 last:border-b-0">
                      <div className="flex items-center gap-2 mb-2">
                        <div className="flex">{renderStars(review.rating)}</div>
                        <span className="text-gray-600">|</span>
                        <span className="text-gray-600">{formatDate(review.date)}</span>
                      </div>
                      <h3 className="font-medium text-gray-900 mb-2">{review.title}</h3>
                      <p className="text-gray-600">{review.comment}</p>
                      <div className="mt-2 text-sm text-gray-500">
                        By {review.author}
                      </div>
                    </div>
                  ))}
                </div>
              ) : (
                <div className="bg-gray-50 rounded-lg p-4 text-gray-600">
                  No reviews available for this training yet.
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default TrainingDetail;