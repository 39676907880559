import React, { useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { Clock, Mail, CheckCircle } from 'lucide-react';

const ComingSoonPage = () => {
  const [email, setEmail] = useState('');
  const [isSubscribed, setIsSubscribed] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    // Here you would typically handle the email subscription
    setIsSubscribed(true);
    setEmail('');
  };

  return (
    <div className="min-h-screen bg-gradient-to-br from-[#1150ef] to-[#1a1e36] flex flex-col items-center justify-center p-4">
      <Helmet>
        <title>Coming Soon | Kigali Capacity Development and Leadership Center</title>
        <meta
          name="description"
          content="Discover and register for professional training programs offered by Kigali Capacity Development and Leadership Center. Enhance your skills with our expert-led courses."
        />
      </Helmet>

      <div className="text-center max-w-3xl mx-auto">
        <div className="mb-8 animate-bounce">
          <Clock className="w-16 h-16 text-[#3DF28E] mx-auto" />
        </div>

        <h1 className="text-5xl font-extrabold mb-6 text-white">
          KCDLC Training Platform
        </h1>

        <p className="text-xl text-white/80 mb-12">
          Your gateway to professional development at Kigali Capacity Development and Leadership Center. 
          Discover, register, and transform your career through our comprehensive training programs.
        </p>

        {!isSubscribed ? (
          <form onSubmit={handleSubmit} className="max-w-md mx-auto mb-12">
            <div className="flex flex-col sm:flex-row gap-4">
              <div className="relative flex-grow">
                <Mail className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400 w-5 h-5" />
                <input
                  type="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  placeholder="Enter your email for launch updates"
                  className="w-full px-12 py-4 rounded-full bg-white/10 text-white placeholder-white/50 border border-white/20 focus:outline-none focus:border-[#3DF28E] focus:ring-2 focus:ring-[#3DF28E]/50"
                  required
                />
              </div>
              <button
                type="submit"
                className="px-4 py-4 bg-[#3DF28E] text-[#1150ef] rounded-full font-semibold hover:bg-[#32e37c] transition-colors shadow-lg"
              >
                Stay Updated
              </button>
            </div>
          </form>
        ) : (
          <div className="flex items-center justify-center space-x-2 text-[#3DF28E] mb-12">
            <CheckCircle className="w-6 h-6" />
            <span className="text-lg">Thank you! We'll keep you updated about our launch.</span>
          </div>
        )}

        <div className="grid grid-cols-1 md:grid-cols-3 gap-8 max-w-4xl mx-auto">
          {[
            {
              number: 'Easy',
              text: 'Training Registration Process',
            },
            {
              number: 'Quality',
              text: 'KCDLC Certified Programs',
            },
            {
              number: 'Expert',
              text: 'Leadership Development',
            },
          ].map((stat, index) => (
            <div
              key={index}
              className="bg-white/5 backdrop-blur-sm rounded-xl p-6 border border-white/10"
            >
              <div className="text-3xl font-bold text-[#3DF28E] mb-2">
                {stat.number}
              </div>
              <div className="text-white/70">{stat.text}</div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default ComingSoonPage;