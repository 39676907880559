import React, { useState, useEffect } from 'react';
import { Search, PlusCircle, MoreVertical, Edit, Trash2, Eye } from 'lucide-react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import API_URL from '../../constants/Constants';
import SidebarLayout from '../Layout/SidebarLayout';

const TrainingDashboard = () => {
  const [trainings, setTrainings] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [deleteConfirmation, setDeleteConfirmation] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    const fetchTrainings = async () => {
      try {
        const response = await axios.get(`${API_URL}training`);
        setTrainings(response.data);
        setLoading(false);
      } catch (err) {
        setError(err);
        setLoading(false);
      }
    };

    fetchTrainings();
  }, []);

  const handleAddTraining = () => {
    navigate('/admin/trainings/create');
  };

  const handleUpdateTraining = (id) => {
    navigate(`/trainings/update/${id}`);
  };

  const handleViewTraining = (id) => {
    navigate(`/admin/trainings/view/${id}`);
  };

  const handleDeleteConfirmation = (training) => {
    setDeleteConfirmation(training);
  };

  const handleDeleteTraining = async () => {
    if (!deleteConfirmation) return;

    try {
      await axios.delete(`${API_URL}training/${deleteConfirmation.id}`);
      setTrainings(trainings.filter(t => t.id !== deleteConfirmation.id));
      setDeleteConfirmation(null);
    } catch (err) {
      console.error('Error deleting training:', err);
    }
  };

  // Filter trainings based on search term
  const filteredTrainings = trainings.filter(training => 
    training.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
    training.instructor.toLowerCase().includes(searchTerm.toLowerCase())
  );

  if (loading) return <SidebarLayout><div className="w-full h-screen flex items-center justify-center">Loading...</div></SidebarLayout> 
  if (error) return <SidebarLayout><div className="w-full text-center text-red-500">Error fetching trainings</div></SidebarLayout>

  return (
    <SidebarLayout>
       <div className="w-full bg-white rounded-lg shadow-md">
      <div className="p-4 md:p-6 border-b">
        <div className="flex flex-col space-y-4 md:space-y-0 md:flex-row md:items-center md:justify-between">
          <h2 className="text-xl md:text-2xl font-bold text-center md:text-left">Trainings</h2>
          <div className="flex flex-col md:flex-row space-y-2 md:space-y-0 md:space-x-4 items-center">
            <div className="relative w-full md:w-64">
              <input
                type="text"
                placeholder="Search Trainings..."
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                className="w-full pl-10 pr-4 py-2 border rounded-lg"
              />
              <Search className="w-5 h-5 text-gray-400 absolute left-3 top-2.5" />
            </div>
            <button
              onClick={handleAddTraining}
              className="w-full md:w-auto flex items-center justify-center gap-2 bg-blue-500 text-white px-4 py-2 rounded-lg hover:bg-blue-600"
            >
              <PlusCircle className="w-5 h-5" />
              Add Training
            </button>
          </div>
        </div>
      </div>

      {/* Mobile View - Card Layout */}
      <div className="block md:hidden p-4">
        {filteredTrainings.map((training) => (
          <div key={training.id} className="bg-white border rounded-lg mb-4 p-4 shadow-sm">
            <div className="flex justify-between items-center mb-2">
              <h3 className="font-bold text-lg">{training.title}</h3>
              <div className="flex space-x-2">
                <button onClick={() => handleViewTraining(training.id)} className="text-gray-400 hover:text-blue-600">
                  <Eye className="w-5 h-5" />
                </button>
                <button onClick={() => handleUpdateTraining(training.id)} className="text-gray-400 hover:text-green-600">
                  <Edit className="w-5 h-5" />
                </button>
                <button onClick={() => handleDeleteConfirmation(training)} className="text-gray-400 hover:text-red-600">
                  <Trash2 className="w-5 h-5" />
                </button>
              </div>
            </div>
            <div className="space-y-1">
              <p><strong>Provider:</strong> {training.instructor}</p>
              <p><strong>Duration:</strong> {training.duration} days</p>
              <p><strong>Fee:</strong> ${training.fee}</p>
              <div>
                <span className={`px-2 py-1 text-xs rounded-full ${training.is_certified ? 'bg-green-100 text-green-800' : 'bg-yellow-100 text-yellow-800'}`}>
                  {training.is_certified ? 'Certified' : 'Non-Certified'}
                </span>
              </div>
            </div>
          </div>
        ))}
      </div>

      {/* Desktop View - Table Layout */}
      <div className="hidden md:block overflow-x-auto">
        <table className="w-full">
          <thead className="bg-gray-50">
            <tr>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Training</th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Training Provider</th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Duration</th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Fee</th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Certification</th>
              <th className="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">Actions</th>
            </tr>
          </thead>
          <tbody className="bg-white divide-y divide-gray-200">
            {filteredTrainings.map((training) => (
              <tr key={training.id}>
                <td className="px-6 py-4 whitespace-nowrap">
                  <div className="font-medium">{training.title}</div>
                </td>
                <td className="px-6 py-4 whitespace-nowrap">{training.instructor}</td>
                <td className="px-6 py-4 whitespace-nowrap">{training.duration} days</td>
                <td className="px-6 py-4 whitespace-nowrap">{training.fee} $</td>
                <td className="px-6 py-4 whitespace-nowrap">
                  <span className={`px-2 py-1 text-xs rounded-full ${training.is_certified ? 'bg-green-100 text-green-800' : 'bg-yellow-100 text-yellow-800'}`}>
                    {training.is_certified ? 'Certified' : 'Non-Certified'}
                  </span>
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-right flex justify-end space-x-2">
                  <button 
                    onClick={() => handleViewTraining(training.id)} 
                    className="text-gray-400 hover:text-blue-600"
                    title="View Training"
                  >
                    <Eye className="w-5 h-5" />
                  </button>
                  <button 
                    onClick={() => handleUpdateTraining(training.id)} 
                    className="text-gray-400 hover:text-green-600"
                    title="Edit Training"
                  >
                    <Edit className="w-5 h-5" />
                  </button>
                  <button 
                    onClick={() => handleDeleteConfirmation(training)} 
                    className="text-gray-400 hover:text-red-600"
                    title="Delete Training"
                  >
                    <Trash2 className="w-5 h-5" />
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {/* Delete Confirmation Modal */}
      {deleteConfirmation && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <div className="bg-white rounded-lg p-6 shadow-xl max-w-md w-full mx-4">
            <h2 className="text-xl font-bold mb-4">Confirm Deletion</h2>
            <p className="mb-6">
              Are you sure you want to delete the training "{deleteConfirmation.title}"?
            </p>
            <div className="flex justify-end space-x-2">
              <button 
                onClick={() => setDeleteConfirmation(null)}
                className="px-4 py-2 bg-gray-200 text-gray-800 rounded hover:bg-gray-300"
              >
                Cancel
              </button>
              <button 
                onClick={handleDeleteTraining}
                className="px-4 py-2 bg-red-500 text-white rounded hover:bg-red-600"
              >
                Delete
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
    </SidebarLayout>
   
  );
};

export default TrainingDashboard;