// import React, { useState, useEffect } from 'react';
// import { Search, Filter, MoreVertical, Edit, Eye, Trash2, BarChart, Users, Calendar, School } from 'lucide-react';
// import { BarChart as RechartsBarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';
// import API_URL from '../../constants/Constants';
// import SidebarLayout from '../Layout/SidebarLayout';

// const EnrollmentDashboard = () => {
//   const [enrollments, setEnrollments] = useState([]);
//   const [selectedEnrollment, setSelectedEnrollment] = useState(null);
//   const [searchTerm, setSearchTerm] = useState('');
//   const [filteredEnrollments, setFilteredEnrollments] = useState([]);
//   const [selectedTraining, setSelectedTraining] = useState('all');
//   const [showDeleteModal, setShowDeleteModal] = useState(false);
//   const [enrollmentToDelete, setEnrollmentToDelete] = useState(null);
//   const [isLoading, setIsLoading] = useState(true);
//   const [error, setError] = useState(null);

//   useEffect(() => {
//     const fetchEnrollments = async () => {
//       try {
//         setIsLoading(true);
//         const response = await fetch(`${API_URL}enrollments`);
//         if (!response.ok) {
//           throw new Error('Failed to fetch enrollments');
//         }
//         const data = await response.json();
//         setEnrollments(data);
//         setFilteredEnrollments(data);
//         setError(null);
//       } catch (err) {
//         setError(err.message);
//         console.error('Error fetching enrollments:', err);
//       } finally {
//         setIsLoading(false);
//       }
//     };

//     fetchEnrollments();
//   }, []);

//   // Analytics calculations
//   const analytics = {
//     totalStudents: enrollments.length,
//     uniqueStudents: new Set(enrollments.map(e => e.email)).size,
//     activeTrainings: new Set(enrollments.map(e => e.training_title)).size,
//     averageEnrollmentsPerTraining: enrollments.length > 0 
//       ? (enrollments.length / new Set(enrollments.map(e => e.training_title)).size).toFixed(1)
//       : '0.0'
//   };

//   // Handler functions
//   const handleViewTraining = (id) => {
//     const enrollment = enrollments.find(e => e.id === id);
//     setSelectedEnrollment(enrollment);
//     console.log('Viewing training:', id);
//   };

//   const handleUpdateTraining = (id) => {
//     const enrollment = enrollments.find(e => e.id === id);
//     setSelectedEnrollment(enrollment);
//     console.log('Updating training:', id);
//   };

//   const handleDeleteConfirmation = (enrollment) => {
//     setEnrollmentToDelete(enrollment);
//     setShowDeleteModal(true);
//   };

//   const handleDelete = async () => {
//     if (enrollmentToDelete) {
//       try {
//         const response = await fetch(`${API_URL}enrollments/${enrollmentToDelete.id}`, {
//           method: 'DELETE',
//         });

//         if (!response.ok) {
//           throw new Error('Failed to delete enrollment');
//         }

//         const updatedEnrollments = enrollments.filter(e => e.id !== enrollmentToDelete.id);
//         setEnrollments(updatedEnrollments);
//         setFilteredEnrollments(updatedEnrollments);
//         setShowDeleteModal(false);
//         setEnrollmentToDelete(null);
//       } catch (err) {
//         console.error('Error deleting enrollment:', err);
//         // You might want to show an error message to the user here
//       }
//     }
//   };

import React, { useState, useEffect } from 'react';
import { Search, Filter, MoreVertical, Edit, Eye, Trash2, BarChart, Users, Calendar, School } from 'lucide-react';
import { BarChart as RechartsBarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';
import API_URL from '../../constants/Constants';
import SidebarLayout from '../Layout/SidebarLayout';

const EnrollmentDashboard = () => {
  const [enrollments, setEnrollments] = useState([]);
  const [selectedEnrollment, setSelectedEnrollment] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredEnrollments, setFilteredEnrollments] = useState([]);
  const [selectedTraining, setSelectedTraining] = useState('all');
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [enrollmentToDelete, setEnrollmentToDelete] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchEnrollments = async () => {
      try {
        setIsLoading(true);
        const response = await fetch(`${API_URL}enrollments`);
        if (!response.ok) {
          throw new Error('Failed to fetch enrollments');
        }
        const data = await response.json();
        setEnrollments(data);
        setFilteredEnrollments(data);
        setError(null);
      } catch (err) {
        setError(err.message);
        console.error('Error fetching enrollments:', err);
      } finally {
        setIsLoading(false);
      }
    };

    fetchEnrollments();
  }, []);

  // Analytics calculations
  const analytics = {
    totalStudents: enrollments.length,
    uniqueStudents: new Set(enrollments.map(e => e.email)).size,
    activeTrainings: new Set(enrollments.map(e => e.training_schedule?.training?.title || 'Unknown')).size,
    averageEnrollmentsPerTraining: enrollments.length > 0
      ? (enrollments.length / new Set(enrollments.map(e => e.training_schedule?.training?.title || 'Unknown')).size).toFixed(1)
      : '0.0'
  };

  // Search and filter functionality
  useEffect(() => {
    const filtered = enrollments.filter(enrollment => {
      const matchesSearch =
        enrollment.fullname.toLowerCase().includes(searchTerm.toLowerCase()) ||
        enrollment.email.toLowerCase().includes(searchTerm.toLowerCase());
      const matchesTraining =
        selectedTraining === 'all' ||
        enrollment.training_schedule?.training?.title === selectedTraining;
      return matchesSearch && matchesTraining;
    });
    setFilteredEnrollments(filtered);
  }, [searchTerm, selectedTraining, enrollments]);

  // Get unique trainings for filter dropdown
  // const uniqueTrainings = ['all', ...new Set(
  //   enrollments.map(e => e.training_schedule?.training?.title || 'Unknown')
  // )];

  // Handler functions remain mostly the same...
  const handleViewTraining = (id) => {
    const enrollment = enrollments.find(e => e.id === id);
    setSelectedEnrollment(enrollment);
    console.log('Viewing training:', id);
  };

  const handleUpdateTraining = (id) => {
    const enrollment = enrollments.find(e => e.id === id);
    setSelectedEnrollment(enrollment);
    console.log('Updating training:', id);
  };

  const handleDeleteConfirmation = (enrollment) => {
    setEnrollmentToDelete(enrollment);
    setShowDeleteModal(true);
  };

  const handleDelete = async () => {
    if (enrollmentToDelete) {
      try {
        const response = await fetch(`${API_URL}enrollments/${enrollmentToDelete.id}`, {
          method: 'DELETE',
        });

        if (!response.ok) {
          throw new Error('Failed to delete enrollment');
        }

        const updatedEnrollments = enrollments.filter(e => e.id !== enrollmentToDelete.id);
        setEnrollments(updatedEnrollments);
        setFilteredEnrollments(updatedEnrollments);
        setShowDeleteModal(false);
        setEnrollmentToDelete(null);
      } catch (err) {
        console.error('Error deleting enrollment:', err);
        // You might want to show an error message to the user here
      }
    }
  };

  // Training distribution data for chart
  const trainingData = Object.entries(
    enrollments.reduce((acc, curr) => {
      acc[curr.training_title] = (acc[curr.training_title] || 0) + 1;
      return acc;
    }, {})
  ).map(([name, count]) => ({ name: name.split('....')[0], count }));

  // Search and filter functionality
  useEffect(() => {
    const filtered = enrollments.filter(enrollment => {
      const matchesSearch = enrollment.fullname.toLowerCase().includes(searchTerm.toLowerCase()) ||
        enrollment.email.toLowerCase().includes(searchTerm.toLowerCase());
      const matchesTraining = selectedTraining === 'all' || enrollment.training_title === selectedTraining;
      return matchesSearch && matchesTraining;
    });
    setFilteredEnrollments(filtered);
  }, [searchTerm, selectedTraining, enrollments]);

  const uniqueTrainings = ['all', ...new Set(enrollments.map(e => e.training_title))];

  // Custom Card component
  const StatCard = ({ title, value, icon: Icon }) => (
    <div className="bg-white p-4 rounded-lg shadow">
      <div className="flex items-center justify-between mb-2">
        <h3 className="text-sm font-medium text-gray-600">{title}</h3>
        {Icon && <Icon className="w-4 h-4 text-gray-500" />}
      </div>
      <div className="text-2xl font-bold">{value}</div>
    </div>
  );

  if (isLoading) {
    return (
      <SidebarLayout>
        <div className="flex items-center justify-center h-screen">
          <div className="text-lg">Loading enrollments...</div>
        </div>
      </SidebarLayout>

    );
  }

  if (error) {
    return (
      <SidebarLayout>
        <div className="flex items-center justify-center h-screen">
          <div className="text-lg text-red-600">Error: {error}</div>
        </div>
      </SidebarLayout>

    );
  }

  return (
    <SidebarLayout>
      <div className="space-y-6 p-6">
        {/* Analytics Cards - updated to use nested data */}
        <div className="grid grid-cols-1 md:grid-cols-4 gap-4">
          <StatCard title="Total Students" value={analytics.totalStudents} icon={Users} />
          <StatCard title="Unique Students" value={analytics.uniqueStudents} icon={Users} />
          <StatCard title="Active Trainings" value={analytics.activeTrainings} icon={School} />
          <StatCard title="Avg. Enrollments/Training" value={analytics.averageEnrollmentsPerTraining} icon={BarChart} />
        </div>

        {/* Existing chart and table code, but with modifications to access nested data */}
        <div className="bg-white rounded-lg shadow">
          <tbody className="bg-white divide-y divide-gray-200">
            {filteredEnrollments.map((enrollment) => (
              <tr key={enrollment.id}>
                <td className="px-6 py-4 whitespace-nowrap">
                  <div className="flex items-center">
                    <div className="w-8 h-8 bg-indigo-100 text-indigo-600 rounded-full flex items-center justify-center">
                      {enrollment.fullname.charAt(0).toUpperCase()}
                    </div>
                    <div className="ml-3">
                      <div className="font-medium">{enrollment.fullname}</div>
                      <div className="text-sm text-gray-500">{enrollment.phone}</div>
                    </div>
                  </div>
                </td>
                <td className="px-6 py-4 whitespace-nowrap">
                  {enrollment.email}
                </td>
                <td className="px-6 py-4 whitespace-nowrap">
                  {enrollment.training_schedule?.training?.title || 'Unknown'}
                </td>
                <td className="px-6 py-4 whitespace-nowrap">
                  <span className={`px-2 py-1 text-xs rounded-full ${enrollment.status === 'active'
                      ? 'bg-green-100 text-green-800'
                      : 'bg-red-100 text-red-800'
                    }`}>
                    {enrollment.status}
                  </span>
                </td>
                <td className="px-6 py-4 whitespace-nowrap">
                  <div className="text-sm">
                    {enrollment.training_schedule
                      ? `${new Date(enrollment.training_schedule.start_date).toLocaleDateString()} - 
                         ${new Date(enrollment.training_schedule.end_date).toLocaleDateString()}`
                      : 'N/A'}
                  </div>
                  <div className="text-xs text-gray-500">
                    Enrolled: {new Date(enrollment.enrollment_date).toLocaleDateString()}
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </div>
      </div>
    </SidebarLayout>
  );
};



export default EnrollmentDashboard;