// import React, { useEffect, useState } from 'react';
// import { Link } from 'react-router-dom';
// import { Helmet } from 'react-helmet-async';
// import { Search, Book, Users, Award, Clock, Globe } from 'lucide-react';
// // import { useLanguage } from '../../contexts/LanguageContext';
// import TrainingList from '../components/Trainings/TrainingList';
// import HeroImg from "./../assets/img/55.jpg";
// import { useLanguage } from '../context/LanguageContext';

// const HomePage = () => {
//   const { translate } = useLanguage();
//   const [translatedContent, setTranslatedContent] = useState({});
  
//   const stats = [
//     { label: 'Active Learners', value: '10,000+', icon: Users },
//     { label: 'Training Completion', value: '94%', icon: Award },
//     { label: 'Expert Trainers', value: '100+', icon: Book },
//     { label: 'Available Trainings', value: '500+', icon: Globe }
//   ];

//   useEffect(() => {
//     const translateContent = async () => {
//       const translated = {
//         title: await translate('Elevate Your Skills, Transform Your Career'),
//         subtitle: await translate('Join Rwanda\'s premier trainings platform. Expert-led trainings designed to accelerate your professional growth.'),
//         exploreButton: await translate('Explore Trainings'),
//         featuredTitle: await translate('Featured Training Programs'),
//         featuredSubtitle: await translate('Curated, expert-led courses to kickstart your professional development journey.'),
//         viewAll: await translate('View All Trainings'),
//         journeyTitle: await translate('Your Learning Journey Starts Here'),
//         journeySubtitle: await translate('Join thousands of successful learners who have transformed their careers through our platform.')
//       };
      
//       setTranslatedContent(translated);
//     };

//     translateContent();
//   }, [translate]);

//   return (
//     <div className="bg-white text-gray-900">
//       <Helmet>
//         <title>{translate('Rwanda Training Hub | Expert Training Platform')}</title>
//         <meta
//           name="description"
//           content={translate('Transform your skills with Rwanda\'s leading trainings platform. 500+ expert-led courses, 10,000+ learners, 94% completion rate.')}
//         />
//       </Helmet>

//       <header className="relative bg-gradient-to-br from-[#1150ef] to-[#1a1e36] text-white py-44 overflow-hidden">
//         <div
//           className="absolute inset-0 bg-cover bg-center opacity-20 blur-sm"
//           style={{
//             backgroundImage: `url(${HeroImg})`,
//             backgroundBlendMode: 'overlay'
//           }}
//         ></div>

//         <div className="container mx-auto px-6 text-center relative z-10">
//           <h1 className="text-5xl font-extrabold mb-6 leading-tight text-white">
//             {translatedContent.title}
//           </h1>
//           <p className="text-xl opacity-80 max-w-2xl mx-auto mb-10">
//             {translatedContent.subtitle}
//           </p>

//           <div className="flex justify-center space-x-4">
//             <Link
//               to="/trainings"
//               className="px-8 py-4 w-1/4 bg-[#3DF28E] text-[#1150ef] rounded-full font-semibold shadow-lg transition-all transform hover:scale-105 hover:bg-[#32e37c] hover:text-[#0e47d9]"
//             >
//               {translatedContent.exploreButton}
//             </Link>
//           </div>
//         </div>
//       </header>

//       <section className="container mx-auto px-6 py-16 bg-white">
//         <div className="grid md:grid-cols-4 gap-6">
//           {stats.map((stat, index) => {
//             const Icon = stat.icon;
//             return (
//               <div
//                 key={index}
//                 className="bg-[#1150ef]/5 p-6 rounded-xl text-center hover:shadow-xl transition-all group"
//               >
//                 <div className="flex justify-center mb-4">
//                   <Icon className="w-10 h-10 text-[#1150ef] group-hover:scale-110 transition-transform" />
//                 </div>
//                 <div className="text-3xl font-bold text-[#1150ef] mb-2">{stat.value}</div>
//                 <div className="text-gray-600">{stat.label}</div>
//               </div>
//             );
//           })}
//         </div>
//       </section>

//       <section className="bg-gray-50">
//         <div className="container max-w-7lg mx-auto px-6 py-16">
//           <div className="text-center mb-12">
//             <h2 className="text-4xl font-bold mb-4 text-gray-800">Featured Training Programs</h2>
//             <p className="text-gray-600 max-w-2xl mx-auto">
//               Curated, expert-led courses to kickstart your professional development journey.
//             </p>
//           </div>
//           <TrainingList limit={3} />
//           <div className="text-center mt-10">
//             <Link
//               to="/trainings"
//               className="px-8 py-4 bg-[#1150ef] text-white rounded-full hover:bg-[#1150ef]/90 transition-colors"
//             >
//               View All Trainings
//             </Link>
//           </div>
//         </div>
//       </section>

//       <section className="bg-white py-16">
//         <div className="container mx-auto px-6">
//           <div className="grid md:grid-cols-3 gap-8">
//             {[
//               {
//                 icon: Book,
//                 title: 'Expert-Driven Learning',
//                 description: 'Learn from industry professionals with real-world insights and cutting-edge expertise.'
//               },
//               {
//                 icon: Clock,
//                 title: 'Flexible Learning',
//                 description: 'Study at your own pace with lifetime access to comprehensive course materials.'
//               },
//               {
//                 icon: Users,
//                 title: 'Collaborative Community',
//                 description: 'Connect with peers, share knowledge, and grow together in a supportive learning environment.'
//               }
//             ].map((feature, index) => (
//               <div
//                 key={index}
//                 className="bg-white p-8 rounded-xl border border-gray-100 hover:shadow-xl transition-all group"
//               >
//                 <div className="flex justify-center mb-6">
//                   <feature.icon className="w-12 h-12 text-[#3DF28E] group-hover:scale-110 transition-transform" />
//                 </div>
//                 <h3 className="text-2xl font-semibold mb-4 text-center text-gray-800">{feature.title}</h3>
//                 <p className="text-gray-600 text-center">{feature.description}</p>
//               </div>
//             ))}
//           </div>
//         </div>
//       </section>

//       <section className="bg-[#1150ef]/10 py-20">
//         <div className="container mx-auto px-6 text-center">
//           <h2 className="text-4xl font-bold mb-6 text-gray-800">Your Learning Journey Starts Here</h2>
//           <p className="text-xl text-gray-600 max-w-2xl mx-auto mb-10">
//             Join thousands of successful learners who have transformed their careers through our platform.
//           </p>
//           <Link
//             to="/trainings"
//             className="px-10 py-4 bg-[#1150ef] text-white rounded-full font-semibold hover:bg-[#1150ef]/90 transition-colors"
//           >
//             Explore All Trainings
//           </Link>
//         </div>
//       </section>
//     </div>
//   );
// };

// export default HomePage;


import React from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { Search, Book, Users, Award, Clock, Globe } from 'lucide-react';
import TrainingList from '../components/Trainings/TrainingList';
import HeroImg from './../assets/img/55.jpg';
import { useTranslation } from '../hooks/useTranslation';

const HomePage = () => {
  const texts = {
    pageTitle: 'Rwanda Training Hub | Expert Training Platform',
    metaDescription:
      'Transform your skills with Rwanda\'s leading trainings platform. 500+ expert-led courses, 10,000+ learners, 94% completion rate.',
    heroTitle: 'Elevate Your Skills, Transform Your Career',
    heroSubtitle:
      'Join Rwanda\'s premier trainings platform. Expert-led trainings designed to accelerate your professional growth.',
    exploreButton: 'Explore Trainings',
    featuredTitle: 'Featured Training Programs',
    featuredSubtitle:
      'Curated, expert-led courses to kickstart your professional development journey.',
    viewAllButton: 'View All Trainings',
    journeyTitle: 'Your Learning Journey Starts Here',
    journeySubtitle:
      'Join thousands of successful learners who have transformed their careers through our platform.',
    expertTitle: 'Expert-Driven Learning',
    expertDescription:
      'Learn from industry professionals with real-world insights and cutting-edge expertise.',
    flexibleTitle: 'Flexible Learning',
    flexibleDescription:
      'Study at your own pace with lifetime access to comprehensive course materials.',
    communityTitle: 'Collaborative Community',
    communityDescription:
      'Connect with peers, share knowledge, and grow together in a supportive learning environment.',
  };

  const { translations, isLoading } = useTranslation(texts);

  const stats = [
    { label: translations.activeLearners || 'Active Learners', value: '10,000+', icon: Users },
    { label: translations.completion || 'Training Completion', value: '94%', icon: Award },
    { label: translations.expertTrainers || 'Expert Trainers', value: '100+', icon: Book },
    { label: translations.availableTrainings || 'Available Trainings', value: '500+', icon: Globe },
  ];

  if (isLoading) {
    return (
      <div className="flex items-center justify-center h-screen">
        <div className="animate-spin rounded-full h-16 w-16 border-t-2 border-b-2 border-[#1150ef]"></div>
      </div>
    );
  }

  return (
    <div className="bg-white text-gray-900">
      {/* Helmet for SEO */}
      <Helmet>
        <title>{translations.pageTitle}</title>
        <meta name="description" content={translations.metaDescription} />
      </Helmet>

      {/* Hero Section */}
      <header className="relative bg-gradient-to-br from-[#1150ef] to-[#1a1e36] text-white py-44 overflow-hidden">
        <div
          className="absolute inset-0 bg-cover bg-center opacity-20 blur-sm"
          style={{
            backgroundImage: `url(${HeroImg})`,
            backgroundBlendMode: 'overlay',
          }}
        ></div>
        <div className="container mx-auto px-6 text-center relative z-10">
          <h1 className="text-5xl font-extrabold mb-6 leading-tight text-white">
            {translations.heroTitle}
          </h1>
          <p className="text-xl opacity-80 max-w-2xl mx-auto mb-10">
            {translations.heroSubtitle}
          </p>
          <Link
            to="/trainings"
            className="px-8 py-4 w-1/4 bg-[#3DF28E] text-[#1150ef] rounded-full font-semibold shadow-lg hover:scale-105 hover:bg-[#32e37c] hover:text-[#0e47d9]"
          >
            {translations.exploreButton}
          </Link>
        </div>
      </header>

      {/* Stats Section */}
      <section className="container mx-auto px-6 py-16">
        <div className="grid md:grid-cols-4 gap-6">
          {stats.map((stat, index) => {
            const Icon = stat.icon;
            return (
              <div
                key={index}
                className="bg-[#1150ef]/5 p-6 rounded-xl text-center hover:shadow-xl transition-all group"
              >
                <Icon className="w-10 h-10 text-[#1150ef] group-hover:scale-110 transition-transform" />
                <div className="text-3xl font-bold text-[#1150ef] mb-2">{stat.value}</div>
                <div className="text-gray-600">{stat.label}</div>
              </div>
            );
          })}
        </div>
      </section>

      {/* Featured Trainings Section */}
      <section className="bg-gray-50">
        <div className="container max-w-7lg mx-auto px-6 py-16">
          <div className="text-center mb-12">
            <h2 className="text-4xl font-bold mb-4 text-gray-800">{translations.featuredTitle}</h2>
            <p className="text-gray-600 max-w-2xl mx-auto">{translations.featuredSubtitle}</p>
          </div>
          <TrainingList limit={3} />
          <div className="text-center mt-10">
            <Link
              to="/trainings"
              className="px-8 py-4 bg-[#1150ef] text-white rounded-full hover:bg-[#1150ef]/90 transition-colors"
            >
              {translations.viewAllButton}
            </Link>
          </div>
        </div>
      </section>

      {/* Features Section */}
      <section className="bg-white py-16">
        <div className="container mx-auto px-6">
          <div className="grid md:grid-cols-3 gap-8">
            {[
              {
                icon: Book,
                title: translations.expertTitle,
                description: translations.expertDescription,
              },
              {
                icon: Clock,
                title: translations.flexibleTitle,
                description: translations.flexibleDescription,
              },
              {
                icon: Users,
                title: translations.communityTitle,
                description: translations.communityDescription,
              },
            ].map((feature, index) => (
              <div
                key={index}
                className="bg-white p-8 rounded-xl border border-gray-100 hover:shadow-xl transition-all group"
              >
                <feature.icon className="w-12 h-12 text-[#3DF28E] group-hover:scale-110 transition-transform" />
                <h3 className="text-2xl font-semibold mb-4 text-center text-gray-800">
                  {feature.title}
                </h3>
                <p className="text-gray-600 text-center">{feature.description}</p>
              </div>
            ))}
          </div>
        </div>
      </section>

      {/* CTA Section */}
      <section className="bg-[#1150ef]/10 py-20">
        <div className="container mx-auto px-6 text-center">
          <h2 className="text-4xl font-bold mb-6 text-gray-800">{translations.journeyTitle}</h2>
          <p className="text-xl text-gray-600 max-w-2xl mx-auto mb-10">
            {translations.journeySubtitle}
          </p>
          <Link
            to="/trainings"
            className="px-10 py-4 bg-[#1150ef] text-white rounded-full font-semibold hover:bg-[#1150ef]/90 transition-colors"
          >
            {translations.exploreButton}
          </Link>
        </div>
      </section>
    </div>
  );
};

export default HomePage;

