import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams, useNavigate } from 'react-router-dom';
import API_URL from '../../constants/Constants';
import { ChevronLeft, Save } from 'lucide-react';
import SidebarLayout from '../Layout/SidebarLayout';

const EditCategoryPage = () => {
    const [categoryName, setCategoryName] = useState('');
    const [description, setDescription] = useState('');
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(true);
    const { id } = useParams();
    const navigate = useNavigate();

    useEffect(() => {
        const fetchCategory = async () => {
            try {
                const response = await axios.get(`${API_URL}categories/${id}`);
                const category = response.data;
                setCategoryName(category.name);
                setDescription(category.description || '');
                setLoading(false);
            } catch (err) {
                console.error('Error fetching category:', err);
                setError('Failed to fetch category details');
                setLoading(false);
            }
        };

        fetchCategory();
    }, [id]);

    const handleSubmit = async (e) => {
        e.preventDefault();

        // Basic validation
        if (!categoryName.trim()) {
            setError('Category name is required');
            return;
        }

        try {
            const response = await axios.put(`${API_URL}categories/${id}`, {
                name: categoryName,
                description: description
            });

            // Redirect back to categories list after successful update
            navigate('/admin/categories');
        } catch (err) {
            console.error('Error updating category:', err);
            setError('Failed to update category. Please try again.');
        }
    };

    const handleCancel = () => {
        navigate('/admin/categories');
    };

    if (loading) return <SidebarLayout><div>Loading...</div></SidebarLayout>;

    return (
        <SidebarLayout>
            <div className="container mx-auto px-4 py-8">
                <div className="bg-white rounded-lg shadow-md p-6">
                    <div className="flex items-center mb-6">
                        <button
                            onClick={handleCancel}
                            className="mr-4 text-gray-600 hover:text-gray-900"
                        >
                            <ChevronLeft className="w-6 h-6" />
                        </button>
                        <h1 className="text-2xl font-bold">Edit Category</h1>
                    </div>

                    <form onSubmit={handleSubmit} className="space-y-4">
                        {error && (
                            <div className="bg-red-50 border border-red-300 text-red-700 px-4 py-3 rounded relative" role="alert">
                                {error}
                            </div>
                        )}

                        <div>
                            <label htmlFor="categoryName" className="block text-sm font-medium text-gray-700">
                                Category Name
                            </label>
                            <input
                                type="text"
                                id="categoryName"
                                value={categoryName}
                                onChange={(e) => setCategoryName(e.target.value)}
                                className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-blue-500 focus:border-blue-500"
                                required
                            />
                        </div>

                        <div>
                            <label htmlFor="description" className="block text-sm font-medium text-gray-700">
                                Description
                            </label>
                            <textarea
                                id="description"
                                value={description}
                                onChange={(e) => setDescription(e.target.value)}
                                rows="4"
                                className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-blue-500 focus:border-blue-500"
                            />
                        </div>

                        <div className="flex justify-end space-x-4">
                            <button
                                type="button"
                                onClick={handleCancel}
                                className="px-4 py-2 bg-gray-200 text-gray-800 rounded hover:bg-gray-300"
                            >
                                Cancel
                            </button>
                            <button
                                type="submit"
                                className="flex items-center gap-2 px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600"
                            >
                                <Save className="w-5 h-5" />
                                Update Category
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </SidebarLayout>

    );
};

export default EditCategoryPage;