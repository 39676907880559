import React, { createContext, useState, useContext } from 'react';

const LanguageContext = createContext();

export const LanguageProvider = ({ children }) => {
  const [currentLanguage, setCurrentLanguage] = useState('en');
  const [translations, setTranslations] = useState({});
  const apiKey = '9ec38cbd-93bb-438b-a878-81393d6fb70b:fx';

  const translateText = async (text) => {
    try {
      const response = await fetch('https://api-free.deepl.com/v2/translate', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
          Authorization: `DeepL-Auth-Key ${apiKey}`,
        },
        body: new URLSearchParams({
          text: text,
          target_lang: currentLanguage.toUpperCase(), // DeepL expects uppercase codes like 'FR', 'ES'
        }),
      })
      

      const data = await response.json();
      return data.translations[0].text;
    } catch (error) {
      console.error('Translation error:', error);
      return text; // Fallback to original text on error
    }
  };

  const translate = async (key) => {
    if (currentLanguage === 'en') return key;

    if (translations[key]) {
      return translations[key];
    }

    const translatedText = await translateText(key);
    setTranslations((prev) => ({
      ...prev,
      [key]: translatedText,
    }));

    return translatedText;
  };

  return (
    <LanguageContext.Provider value={{ currentLanguage, setCurrentLanguage, translate }}>
      {children}
    </LanguageContext.Provider>
  );
};

export const useLanguage = () => useContext(LanguageContext);
