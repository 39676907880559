import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { PlusCircle, Trash2, Calendar } from 'lucide-react';
import API_URL from '../../constants/Constants';
import SidebarLayout from '../Layout/SidebarLayout';
import RichTextEditor from '../ui/RichTextEditor';

const AddTrainingPage = () => {
  const [training, setTraining] = useState({
    title: '',
    description: '',
    details: '',
    duration: '',
    instructor: 'Training In Rwanda',
    fee: '', // Discounted fee
    original_fee: '', // Original price
    discount_percentage: '0', // Discount percentage as a string
    level: 'Beginner',
    is_certified: false,
    what_you_will_learn: [''],
    address: '',
    category_id: '' // Category
  });

  const [schedules, setSchedules] = useState([]);
  const [categories, setCategories] = useState([]); // State to store categories

  // Discount percentage options
  const discountOptions = Array.from({ length: 10 }, (_, i) => ((i + 1) * 10).toString());

  // Effect to calculate discounted fee when original fee or discount changes
  useEffect(() => {
    const originalFee = parseFloat(training.original_fee);
    const discountPercentage = parseFloat(training.discount_percentage);

    if (!isNaN(originalFee) && !isNaN(discountPercentage)) {
      const discountedFee = originalFee * (1 - discountPercentage / 100);
      setTraining(prev => ({
        ...prev,
        fee: discountedFee.toFixed(2)
      }));
    }
  }, [training.original_fee, training.discount_percentage]);

  // Fetch categories when component mounts
  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const response = await fetch(`${API_URL}categories`);
        const data = await response.json();
        setCategories(data);

        // Set default category if categories exist
        if (data.length > 0) {
          setTraining(prev => ({
            ...prev,
            category_id: data[0].id // Default to first category
          }));
        }
      } catch (error) {
        console.error('Error fetching categories:', error);
        alert('Failed to fetch categories');
      }
    };

    fetchCategories();
  }, []);

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setTraining(prev => ({
      ...prev,
      [name]: type === 'checkbox' ? checked : value
    }));
  };

  const handleEditorChange = (content) => {
    setTraining(prev => ({
      ...prev,
      details: content
    }));
  };
  
  const handleLearningOutcomeChange = (index, value) => {
    const newOutcomes = [...training.what_you_will_learn];
    newOutcomes[index] = value;
    setTraining(prev => ({ ...prev, what_you_will_learn: newOutcomes }));
  };

  const addLearningOutcome = () => {
    setTraining(prev => ({
      ...prev,
      what_you_will_learn: [...prev.what_you_will_learn, '']
    }));
  };

  const addSchedule = () => {
    setSchedules([...schedules, { start_date: '', end_date: '' }]);
  };

  const updateSchedule = (index, field, value) => {
    const newSchedules = [...schedules];
    newSchedules[index][field] = value;
    setSchedules(newSchedules);
  };

  const removeSchedule = (index) => {
    const newSchedules = schedules.filter((_, i) => i !== index);
    setSchedules(newSchedules);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Convert duration to an integer
    const trainingData = {
      ...training,
      duration: Number(training.duration) // Convert duration to integer
    };

    try {
      // Create training first
      const trainingResponse = await fetch(`${API_URL}training`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(trainingData)
      });
      const trainingDataResponse = await trainingResponse.json();
      console.log(trainingDataResponse.training.id);
      const trainingId = trainingDataResponse.training.id;

      // Create schedules for the training
      for (const schedule of schedules) {
        const myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");

        const raw = JSON.stringify({
          training_id: trainingId,
          start_date: schedule.start_date,
          end_date: schedule.end_date
        });

        const requestOptions = {
          method: "POST",
          headers: myHeaders,
          body: raw,
          redirect: "follow"
        };

        const response = await fetch(`${API_URL}training-schedules`, requestOptions);
        const result = await response.text();
        console.log(result);
      }

      alert('Training and Schedules created successfully!');


      setTraining({
        title: '',
        description: '',
        duration: '',
        instructor: 'Training In Rwanda',
        fee: '',
        original_fee: '',
        discount_percentage: '',
        level: 'Beginner',
        is_certified: false,
        what_you_will_learn: [''],
        address: '',
        category_id: categories.length > 0 ? categories[0].id : ''
      });
      setSchedules([]);

    } catch (error) {
      console.error('Error creating training:', error);
      alert('Failed to create training');
    }
  };



  return (
    <SidebarLayout>
      <div className="max-w-4xl m-8 mx-auto p-6 bg-white rounded-lg shadow-md">
        <h2 className="text-2xl font-bold mb-6">Add New Training</h2>
        <form onSubmit={handleSubmit} className="space-y-6">
          <div className="grid md:grid-cols-2 gap-4">
            <div>
              <label className="block mb-2">Training Title</label>
              <input
                type="text"
                name="title"
                value={training.title}
                onChange={handleChange}
                className="w-full px-3 py-2 border rounded-lg"
                required
              />
            </div>
            <div>
              <label className="block mb-2">Training Provider</label>
              <input
                type="text"
                name="instructor"
                value={training.instructor}
                onChange={handleChange}
                className="w-full px-3 py-2 border rounded-lg"
                required
              />
            </div>
          </div>

          <div>
            <label className="block mb-2">Category</label>
            <select
              name="category_id"
              value={training.category_id}
              onChange={handleChange}
              className="w-full px-3 py-2 border rounded-lg"
              required
            >
              {categories.map((category) => (
                <option key={category.id} value={category.id}>
                  {category.name}
                </option>
              ))}
            </select>
          </div>

          <div>
            <label className="block mb-2">Training Summary </label>
            <textarea
              name="description"
              value={training.description}
              onChange={handleChange}
              className="w-full px-3 py-2 border rounded-lg"
              rows="4"
              required
            />
          </div>

          <div>
            <label className="block mb-2">Training Details</label>
            <RichTextEditor
              value={training.details}
              onChange={handleEditorChange}
              placeholder="Enter training details..."
              className="mb-4"
            />
          </div>

          <div className="grid md:grid-cols-3 mt-4 gap-4">
            <div>
              <label className="block mb-2">Duration (Days)</label>
              <input
                type="number"
                name="duration"
                value={training.duration}
                onChange={handleChange}
                className="w-full px-3 py-2 border rounded-lg"
                required
              />
            </div>
            <div>
              <label className="block mb-2">Original Fee ($)</label>
              <input
                type="number"
                name="original_fee"
                value={training.original_fee}
                onChange={handleChange}
                className="w-full px-3 py-2 border rounded-lg"
                required
                min="0"
                step="0.01"
              />
            </div>
            <div>
              <label className="block mb-2">Discounted Fee ($)</label>
              <input
                type="number"
                name="fee"
                value={training.fee}
                readOnly // Make this read-only as it's calculated automatically
                className="w-full px-3 py-2 border rounded-lg bg-gray-100 cursor-not-allowed"
              />
            </div>
          </div>

          <div className="grid md:grid-cols-2 gap-4">
            <div>
              <label className="block mb-2">Discount Percentage (%)</label>
              <select
                name="discount_percentage"
                value={training.discount_percentage}
                onChange={handleChange}
                className="w-full px-3 py-2 border rounded-lg"
              >
                <option value="0">0%</option>
                {discountOptions.map((percentage) => (
                  <option key={percentage} value={percentage}>
                    {percentage}%
                  </option>
                ))}
              </select>
            </div>
            <div>
              <label className="block mb-2">Level</label>
              <select
                name="level"
                value={training.level}
                onChange={handleChange}
                className="w-full px-3 py-2 border rounded-lg"
              >
                <option value="Beginner">Beginner</option>
                <option value="Intermediate">Intermediate</option>
                <option value="Advanced">Advanced</option>
              </select>
            </div>
          </div>

          <div>
            <label className="block mb-2">Address</label>
            <input
              type="text"
              name="address"
              value={training.address}
              onChange={handleChange}
              className="w-full px-3 py-2 border rounded-lg"
              required
            />
          </div>

          <div className="flex items-center mb-2">
            <input
              type="checkbox"
              name="is_certified"
              checked={training.is_certified}
              onChange={handleChange}
              className="mr-2"
            />
            <label>Certification Available</label>
          </div>

          <div>
            <div className="flex justify-between items-center mb-2">
              <h3 className="font-semibold">Learning Outcomes</h3>
              <button
                type="button"
                onClick={addLearningOutcome}
                className="flex items-center text-blue-500 hover:text-blue-600"
              >
                <PlusCircle className="mr-2 w-5 h-5" /> Add Outcome
              </button>
            </div>
            {training.what_you_will_learn.map((outcome, index) => (
              <div key={index} className="flex items-center mb-2">
                <input
                  type="text"
                  value={outcome}
                  onChange={(e) => handleLearningOutcomeChange(index, e.target.value)}
                  className="flex-grow px-3 py-2 border rounded-lg mr-2"
                  placeholder="Learning outcome"
                />
              </div>
            ))}
          </div>

          <div className="border-t pt-4">
            <div className="flex justify-between items-center mb-4">
              <h3 className="font-semibold">Training Schedules</h3>
              <button
                type="button"
                onClick={addSchedule}
                className="flex items-center text-blue-500 hover:text-blue-600"
              >
                <Calendar className="mr-2 w-5 h-5" /> Add Schedule
              </button>
            </div>
            {schedules.map((schedule, index) => (
              <div key={index} className="flex items-center mb-2 space-x-2">
                <div className="flex-grow">
                  <label className="block text-sm mb-1">Start Date</label>
                  <input
                    type="date"
                    value={schedule.start_date}
                    onChange={(e) => updateSchedule(index, 'start_date', e.target.value)}
                    className="w-full px-3 py-2 border rounded-lg"
                    required
                  />
                </div>
                <div className="flex-grow">
                  <label className="block text-sm mb-1">End Date</label>
                  <input
                    type="date"
                    value={schedule.end_date}
                    onChange={(e) => updateSchedule(index, 'end_date', e.target.value)}
                    className="w-full px-3 py-2 border rounded-lg"
                    required
                  />
                </div>
                <button
                  type="button"
                  onClick={() => removeSchedule(index)}
                  className="text-red-500 hover:text-red-600 self-end mb-2"
                >
                  <Trash2 className="w-5 h-5" />
                </button>
              </div>
            ))}
          </div>

          <div className="text-right">
            <button
              type="submit"
              className="bg-blue-500 text-white px-6 py-2 rounded-lg hover:bg-blue-600 transition duration-300"
            >
              Create Training
            </button>
          </div>
        </form>
      </div>
    </SidebarLayout>

  );
};

export default AddTrainingPage;