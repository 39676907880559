import { useState, useEffect, useCallback } from 'react';
import { useLanguage } from '../context/LanguageContext';

export const useTranslation = (texts) => {
  const { currentLanguage, translate } = useLanguage();
  const [translations, setTranslations] = useState({});
  const [isLoading, setIsLoading] = useState(true);

  const translateTexts = useCallback(async () => {
    if (currentLanguage === 'en') {
      const englishTranslations = Object.keys(texts).reduce((acc, key) => {
        acc[key] = texts[key];
        return acc;
      }, {});
      setTranslations(englishTranslations);
      setIsLoading(false);
      return;
    }

    try {
      const translatedTexts = {};
      for (const [key, value] of Object.entries(texts)) {
        translatedTexts[key] = await translate(value);
      }
      setTranslations(translatedTexts);
    } catch (error) {
      console.error('Translation error:', error);
      setTranslations(texts); // Fallback to original texts
    } finally {
      setIsLoading(false);
    }
  }, [currentLanguage, texts, translate]);

  useEffect(() => {
    translateTexts();
  }, [translateTexts, currentLanguage]);

  return { translations, isLoading };
};
