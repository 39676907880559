
// import React, { useState, useEffect } from 'react';

// import { Link, useLocation } from 'react-router-dom';
// import axios from 'axios';
// import API_URL from '../../constants/Constants';
// import logo from "./../../assets/img/logo.png"

// const Navbar = () => {
//   const [isMenuOpen, setIsMenuOpen] = useState(false);
//   const [isCategoryDropdownOpen, setIsCategoryDropdownOpen] = useState(false);
//   const [categories, setCategories] = useState([]);
//   const location = useLocation();

//   // Dummy auth state - replace with your actual auth logic
//   const isAuthenticated = false;

//   // Fetch categories on component mount
//   useEffect(() => {
//     const fetchCategories = async () => {
//       try {
//         const response = await axios.get(`${API_URL}categories`);
//         setCategories(response.data);
//       } catch (error) {
//         console.error('Error fetching categories:', error);
//       }
//     };

//     fetchCategories();
//   }, []);

//   const toggleMenu = () => setIsMenuOpen(!isMenuOpen);
//   const toggleCategoryDropdown = () => setIsCategoryDropdownOpen(!isCategoryDropdownOpen);

//   // Check if the current path matches the link
//   const isActivePath = (path) => location.pathname === path;

//   return (
//     <nav className="bg-white shadow-md">
//       <div className="container mx-auto px-4">
//         <div className="flex justify-between items-center h-28">
//           {/* Logo */}
//           <Link to="/" className="flex items-center">
//             <img
//               src={logo}
//               alt="Logo"
//               className="h-28 w-auto p-1"
//             />
//           </Link>

//           {/* Desktop Navigation */}
//           <div className="hidden md:flex items-center space-x-8">
//           <Link 
//               to="/"
//               className={`text-gray-700 hover:text-[#1150ef] transition-colors ${isActivePath('/courses') ? 'text-[#1150ef]' : ''}`}
//             >
//               Home
//             </Link>
//             {/* Categories Dropdown */}
//             <div className="relative">
//               <button
//                 onClick={toggleCategoryDropdown}
//                 className={`text-gray-700 hover:text-[#1150ef] transition-colors flex items-center ${isActivePath('/categories') ? 'text-[#1150ef]' : ''}`}
//               >
//                 Categories
//                 <svg className="w-4 h-4 ml-1" fill="none" stroke="currentColor" viewBox="0 0 24 24">
//                   <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 9l-7 7-7-7" />
//                 </svg>
//               </button>

//               {isCategoryDropdownOpen && (
//                 <div className="absolute left-0 mt-2 w-48 bg-white rounded-md shadow-lg py-1 z-50">
//                   {categories.map((category) => (
//                     <Link 
//                       key={category.id} 
//                       to={`/category/${category.id}`} 
//                       className="block px-4 py-2 text-gray-700 hover:bg-[#3DF28E]/10"
//                       onClick={() => setIsCategoryDropdownOpen(false)}
//                     >
//                       {category.name}
//                     </Link>
//                   ))}
//                 </div>
//               )}
//             </div>

//             <Link 
//               to="/trainings"
//               className={`text-gray-700 hover:text-[#1150ef] transition-colors ${isActivePath('/courses') ? 'text-[#1150ef]' : ''}`}
//             >
//               All Trainings
//             </Link>

//             <Link 
//               to="/about"
//               className={`text-gray-700 hover:text-[#1150ef] transition-colors ${isActivePath('/about') ? 'text-[#1150ef]' : ''}`}
//             >
//               About
//             </Link>
            
//             <Link 
//               to="/contact"
//               className={`text-gray-700 hover:text-[#1150ef] transition-colors ${isActivePath('/contact') ? 'text-[#1150ef]' : ''}`}
//             >
//               Contact
//             </Link>
//           </div>

//           {/* Mobile Menu Button */}
//           <button
//             onClick={toggleMenu}
//             className="md:hidden rounded-md p-2 text-gray-700 hover:bg-[#3DF28E]/10 focus:outline-none"
//           >
//             <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24">
//               <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d={isMenuOpen ? "M6 18L18 6M6 6l12 12" : "M4 6h16M4 12h16M4 18h16"} />
//             </svg>
//           </button>
//         </div>

//         {/* Mobile Menu */}
//         {isMenuOpen && (
//           <div className="md:hidden py-4">
//             <div className="flex flex-col space-y-4">
//               {/* Mobile Search */}
//               <div className="px-4 mb-2">
//                 <input
//                   type="text"
//                   placeholder="Search Trainings..."
//                   className="w-full px-4 py-2 text-gray-700 bg-gray-100 rounded-md focus:outline-none focus:ring-2 focus:ring-[#1150ef] focus:bg-white"
//                 />
//               </div>

//               {/* Categories Dropdown for Mobile */}
//               <Link to="/" className="px-4 py-2 text-gray-700 hover:bg-[#3DF28E]/10">Home</Link>
//               <div>
//                 <button
//                   onClick={toggleCategoryDropdown}
//                   className="w-full text-left px-4 py-2 text-gray-700 hover:bg-[#3DF28E]/10 flex justify-between items-center"
//                 >
//                   Categories
//                   <svg className="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24">
//                     <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 9l-7 7-7-7" />
//                   </svg>
//                 </button>
                

//                 {isCategoryDropdownOpen && (
//                   <div className="pl-4">
//                     {categories.map((category) => (
//                       <Link 
//                         key={category.id} 
//                         to={`/category/${category.id}`} 
//                         className="block px-4 py-2 text-gray-700 hover:bg-[#3DF28E]/10"
//                         onClick={() => {
//                           setIsCategoryDropdownOpen(false);
//                           setIsMenuOpen(false);
//                         }}
//                       >
//                         {category.name}
//                       </Link>
//                     ))}
//                   </div>
//                 )}
//               </div>

//               <Link to="/trainings" className="px-4 py-2 text-gray-700 hover:bg-[#3DF28E]/10">All Trainings</Link>
//               <Link to="/about" className="px-4 py-2 text-gray-700 hover:bg-[#3DF28E]/10">About</Link>
//               <Link to="/contact" className="px-4 py-2 text-gray-700 hover:bg-[#3DF28E]/10">Contact</Link>
              
//               {!isAuthenticated && (
//                 <div className="px-4 py-2 space-y-2">
//                   <Link
//                     to="/login"
//                     className="block w-full text-center px-4 py-2 bg-[#1150ef] text-white rounded-md hover:bg-[#0e47d9]"
//                   >
//                     Login
//                   </Link>
//                 </div>
//               )}
//             </div>
//           </div>
//         )}
//       </div>
//     </nav>
//   );
// };

// export default Navbar;

import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import axios from 'axios';
// import { useLanguage } from '../../contexts/LanguageContext';
import API_URL from '../../constants/Constants';
import logo from "./../../assets/img/logo.png";
import { Globe } from 'lucide-react';
import { useLanguage } from '../../context/LanguageContext';

const Navbar = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isCategoryDropdownOpen, setIsCategoryDropdownOpen] = useState(false);
  const [isLanguageDropdownOpen, setIsLanguageDropdownOpen] = useState(false);
  const [categories, setCategories] = useState([]);
  const [translations, setTranslations] = useState({
    home: 'Home',
    categories: 'Categories',
    allTrainings: 'All Trainings',
    about: 'About',
    contact: 'Contact'
  });
  const location = useLocation();
  const { currentLanguage, setCurrentLanguage, translate } = useLanguage();

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const response = await axios.get(`${API_URL}categories`);
        setCategories(response.data);
      } catch (error) {
        console.error('Error fetching categories:', error);
      }
    };

    fetchCategories();
  }, []);

  useEffect(() => {
    const translateContent = async () => {
      const translated = {
        home: await translate('Home'),
        categories: await translate('Categories'),
        allTrainings: await translate('All Trainings'),
        about: await translate('About'),
        contact: await translate('Contact')
      };
      setTranslations(translated);
    };

    translateContent();
  }, [currentLanguage, translate]);

  const toggleMenu = () => setIsMenuOpen(!isMenuOpen);
  const toggleCategoryDropdown = () => setIsCategoryDropdownOpen(!isCategoryDropdownOpen);
  const toggleLanguageDropdown = () => setIsLanguageDropdownOpen(!isLanguageDropdownOpen);

  const changeLanguage = (lang) => {
    setCurrentLanguage(lang);
    setIsLanguageDropdownOpen(false);
  };

  const isActivePath = (path) => location.pathname === path;

  return (
    <nav className="bg-white shadow-md">
      <div className="container mx-auto px-4">
        <div className="flex justify-between items-center h-28">
          <Link to="/home" className="flex items-center">
            <img src={logo} alt="Logo" className="h-28 w-auto p-1" />
          </Link>

          <div className="hidden md:flex items-center space-x-8">
            <Link to="/home" className={`text-gray-700 hover:text-[#1150ef] transition-colors ${isActivePath('/') ? 'text-[#1150ef]' : ''}`}>
              {translations.home}
            </Link>
            
            <div className="relative">
              <button
                onClick={toggleCategoryDropdown}
                className={`text-gray-700 hover:text-[#1150ef] transition-colors flex items-center ${isActivePath('/categories') ? 'text-[#1150ef]' : ''}`}
              >
                {translations.categories}
                <svg className="w-4 h-4 ml-1" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 9l-7 7-7-7" />
                </svg>
              </button>

              {isCategoryDropdownOpen && (
                <div className="absolute left-0 mt-2 w-48 bg-white rounded-md shadow-lg py-1 z-50">
                  {categories.map((category) => (
                    <Link 
                      key={category.id} 
                      to={`/category/${category.id}`} 
                      className="block px-4 py-2 text-gray-700 hover:bg-[#3DF28E]/10"
                      onClick={() => setIsCategoryDropdownOpen(false)}
                    >
                      {category.name}
                    </Link>
                  ))}
                </div>
              )}
            </div>

            <Link to="/trainings" className={`text-gray-700 hover:text-[#1150ef] transition-colors ${isActivePath('/trainings') ? 'text-[#1150ef]' : ''}`}>
              {translations.allTrainings}
            </Link>

            <Link to="/about" className={`text-gray-700 hover:text-[#1150ef] transition-colors ${isActivePath('/about') ? 'text-[#1150ef]' : ''}`}>
              {translations.about}
            </Link>
            
            <Link to="/contact" className={`text-gray-700 hover:text-[#1150ef] transition-colors ${isActivePath('/contact') ? 'text-[#1150ef]' : ''}`}>
              {translations.contact}
            </Link>

            {/* Language Selector */}
            <div className="relative">
              <button
                onClick={toggleLanguageDropdown}
                className="flex items-center text-gray-700 hover:text-[#1150ef] transition-colors"
              >
                <Globe className="w-5 h-5 mr-1" />
                {currentLanguage.toUpperCase()}
              </button>

              {isLanguageDropdownOpen && (
                <div className="absolute right-0 mt-2 w-48 bg-white rounded-md shadow-lg py-1 z-50">
                  <button
                    onClick={() => changeLanguage('en')}
                    className="block w-full text-left px-4 py-2 text-gray-700 hover:bg-[#3DF28E]/10"
                  >
                    English
                  </button>
                  <button
                    onClick={() => changeLanguage('fr')}
                    className="block w-full text-left px-4 py-2 text-gray-700 hover:bg-[#3DF28E]/10"
                  >
                    Français
                  </button>
                </div>
              )}
            </div>
          </div>

          {/* Mobile menu button */}
          <button
            onClick={toggleMenu}
            className="md:hidden rounded-md p-2 text-gray-700 hover:bg-[#3DF28E]/10 focus:outline-none"
          >
            <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d={isMenuOpen ? "M6 18L18 6M6 6l12 12" : "M4 6h16M4 12h16M4 18h16"} />
            </svg>
          </button>
        </div>

        {/* Mobile menu */}
        {isMenuOpen && (
          <div className="md:hidden py-4">
            <div className="flex flex-col space-y-4">
              <Link to="/" className="px-4 py-2 text-gray-700 hover:bg-[#3DF28E]/10">
                {translations.home}
              </Link>
              
              <div>
                <button
                  onClick={toggleCategoryDropdown}
                  className="w-full text-left px-4 py-2 text-gray-700 hover:bg-[#3DF28E]/10 flex justify-between items-center"
                >
                  {translations.categories}
                  <svg className="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 9l-7 7-7-7" />
                  </svg>
                </button>

                {isCategoryDropdownOpen && (
                  <div className="pl-4">
                    {categories.map((category) => (
                      <Link 
                        key={category.id} 
                        to={`/category/${category.id}`} 
                        className="block px-4 py-2 text-gray-700 hover:bg-[#3DF28E]/10"
                        onClick={() => {
                          setIsCategoryDropdownOpen(false);
                          setIsMenuOpen(false);
                        }}
                      >
                        {category.name}
                      </Link>
                    ))}
                  </div>
                )}
              </div>

              <Link to="/trainings" className="px-4 py-2 text-gray-700 hover:bg-[#3DF28E]/10">
                {translations.allTrainings}
              </Link>

              <Link to="/about" className="px-4 py-2 text-gray-700 hover:bg-[#3DF28E]/10">
                {translations.about}
              </Link>

              <Link to="/contact" className="px-4 py-2 text-gray-700 hover:bg-[#3DF28E]/10">
                {translations.contact}
              </Link>

              {/* Mobile Language Selector */}
              <div className="px-4 py-2">
                <button
                  onClick={() => changeLanguage('en')}
                  className={`block w-full text-left py-2 text-gray-700 hover:bg-[#3DF28E]/10 ${currentLanguage === 'en' ? 'font-bold' : ''}`}
                >
                  English
                </button>
                <button
                  onClick={() => changeLanguage('fr')}
                  className={`block w-full text-left py-2 text-gray-700 hover:bg-[#3DF28E]/10 ${currentLanguage === 'fr' ? 'font-bold' : ''}`}
                >
                  Français
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
    </nav>
  );
};

export default Navbar;